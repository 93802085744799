import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import SubpageTopimage from "../components/SubpageTopimage"

const Title = styled.h1``

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    /* border-bottom: 3px solid ${colors.black}; */
    margin-bottom: 3rem;
  }
`

const Textwrapper = styled.div`
  padding: 0.8rem;
  flex: 1 0 auto;
  width: 100%;
  ${mq.tablet} {
    padding: 1rem 1rem 0;
    width: 35%;
    margin-right: 1rem;
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

// const SubCats = ({data}) => (
//   {data.wordpressPage.acf.leistungenstypen_page.subcategories.map(subcats => (
//     <div>
//       {subcats.text}
//     </div>
//   ))
// )

const LeistungenPageTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <SubpageTopimage
      objectFit="cover"
      objectPosition="50% 50%"
      fluid={data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
      alt={data.wordpressPage.title}
    />
    <Wrapper>
      <Textwrapper>
        <Title>{data.wordpressPage.title}</Title>

        {data.wordpressPage.acf.leistungenstypen_page.map((item, i) => (
          <section id={item.title} key={i}>
            <img
              src={item.symbol.url.localFile.publicURL}
              style={{
                float: "right",
                paddingLeft: "1rem",
                paddingBottom: "1rem1",
              }}
              alt={item.title}
            />
            <h2>{item.title}</h2>
            <p>{item.desc}</p>

            {item.subcategories !== null && (
              <div>
                {item.subcategories.map((subcat, i) => (
                  <div key={i}>
                    <h3>{subcat.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: subcat.text }} />
                  </div>
                ))}
              </div>
            )}

            <hr style={{ margin: "4rem 0" }} />
          </section>
        ))}

        {/* <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} /> */}
      </Textwrapper>
    </Wrapper>
  </Layout>
)
export default LeistungenPageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      acf {
        leistungenstypen_page {
          title
          desc
          symbol {
            url {
              localFile {
                publicURL
              }
            }
          }
          subcategories {
            title
            text
          }
        }
      }
    }
  }
`
