import styled from "styled-components"
import { mq } from "../utils/presets"
import Img from "gatsby-image/withIEPolyfill"

const SubpageTopimage = styled(Img)`
  height: 215px;
  ${mq.tablet} {
    height: calc(80vh - 230px);
  }
`

export default SubpageTopimage
